<template>
  <router-view class="router-view" v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-buttom: 60px*/;
}
.router-view{
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}
</style>
